<template>
  <div class="app-container">
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">Evaluation Employee Salary</h3>
      </div>
        <el-row :gutter="5" type="flex" justify="end">
          <el-col :span="3">
            <el-select v-model="selectedYear" size="mini" clearable @change="getEval" placeholder="Year">
              <el-option  v-for="year in yearList" :key="year.id" :value="year.year" :label="year.year"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="selectedEval" size="mini" clearable placeholder="Evaluation Name" :disabled="selectedYear === ''" @change="getTableData">
              <el-option  v-for="evaluation in evaluations" :key="evaluation.id" :value="evaluation.id" :label="evaluation.name"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="selectedPayMaster" size="mini" clearable placeholder="Payroll Master">
              <el-option v-for="payMaster in payMasters" :key="payMaster.id" :value="payMaster.name" :label="payMaster.name"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <download-excel class= "el-button el-button--mini" style="background:#EB2C3E; color:white; font-weight: normal !important;" header="Employee Salary List" :name="'Employee Salary List' + '-' + formatDate(new Date())" :fetch="downloadFile">Download Selected Documents</download-excel>
          </el-col>

          <el-col :span="12">
            <drag-drop-upload @success="getTableData" :selectedEval="selectedEval" v-show="selectedEval !== ''" />
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="20">
            <h2>Employee</h2>
          </el-col>
          <el-col :span="4" style="float: right;">
            <el-input
              v-model="employeeSearchText"
              size="mini"
              @click="hello(scope.row)"
              placeholder="Search for Employee"
            />
          </el-col>
        </el-row>
        <el-row>
          <el-table
            :data="salaryTable.filter(data => data.paymaster_em_id_name !== null ? !selectedPayMaster && !employeeSearchText || data.paymaster_em_id_name.toLowerCase().includes(selectedPayMaster.toLowerCase()) && data.em_id_name.toLowerCase().includes(employeeSearchText.toLowerCase()) : '')"
            >
            <el-table-column
              prop="empid"
              label="Employee ID"
              >
            </el-table-column>
            <el-table-column
              prop="em_id_name"
              label="Name"
              >
            </el-table-column>
            <el-table-column
              prop="paymaster_em_id_name"
              label="Payroll Master"
              >
            </el-table-column>
            <el-table-column
              prop="basicsalary"
              label="Salary"
              >
            </el-table-column>
          </el-table>
        </el-row>
    </el-card>
  </div>
</template>
<script>
import { format } from 'date-fns'
import DragDropUpload from '@/components/DragDropUpload/DragDropUpload.vue'
export default {
  components: {
    DragDropUpload
  },
  data () {
    return {
      dialogUpload: false,
      selectedYear: '',
      selectedEval: '',
      evaluations: [],
      json_data: [],
      salaryTable: [],
      payMasters: [],
      selectedPayMaster: '',
      yearList: [],
      completedEvalList: [],
      json_fields: {},
      departments: [],
      sections: [],
      employeeSearchText: '',
      fileList: [],
      isUploadDisabled: false
    }
  },
  computed: {
    fileListLength () {
      return this.fileList.length
    }
  },
  watch: {
    fileListLength (val, oldVal) {
      if (val === 1) {
        this.isUploadDisabled = true
      }
      if (val === 0) {
        this.isUploadDisabled = false
      }
    }
  },
  created () {
    this.getCompletedEval()
    this.getDepartments()
    this.getPayMaster()
  },
  methods: {
    handleChange (file, fileList) {
      // if (fileList.length > 1) {
      //   console.log('greater than 1')
      //   // var subtract = fileList.length - 1
      //   // this.fileList = fileList.slice(0)
      //   fileList.pop()
      //   this.fileList = fileList
      // } else {
      this.fileList = fileList
      // }
    },
    formatDate (date) {
      return format(date, 'PPpp')
    },
    async downloadFile () {
      var formSubmit = {}
      formSubmit.evaluation_id = this.selectedEval
      const response = await this.$http.post('mpp.evaluation-download-file', formSubmit)
      if (response.status === 200) {
        this.$message({
          showClose: true,
          message: 'Form Submitted',
          type: 'success'
        })
        if (Object.prototype.hasOwnProperty.call(response.data, 'json_data')) {
          this.json_fields = response.data.json_field
          return response.data.json_data
        } else {
          this.$message({
            showClose: true,
            message: 'No reports found!',
            type: 'error'
          })
        }
      }
    },
    getTableData () {
      this.salaryTable = []
      this.$http
        .get('mpp.evaluation-employee-salary/' + this.selectedEval)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.salaryTable = []
          } else {
            this.salaryTable = res.data.body
          }
        })
    },
    getPayMaster () {
      this.$http
        .get('/em/employee-paymaster' + this.selectedYear)
        .then(res => {
          this.payMasters = res.data.body
        })
    },
    getEval () {
      this.$http
        .get('mpp.evaluation-list-by-year/' + this.selectedYear)
        .then(res => {
          this.evaluations = res.data.body
        })
    },
    async generateReport () {
      this.$refs.reportForm.validate()
      // if (this.validateFields()) {
      const jsonFields = {}
      this.reportForm.selectedFields.forEach(e => (
        jsonFields[e.property_name] = e.property_value
      ))
      var formSubmit = {}
      formSubmit.evaluation_id = this.reportForm.selectedEval
      formSubmit.reports_id = this.reportForm.selectedReport
      formSubmit.bd_id = this.reportForm.selectedDept
      formSubmit.bds_id = [14, 15]
      formSubmit.fields = jsonFields
      formSubmit.group_by = this.selectedGroupBy
      const response = await this.$http.post('reports', formSubmit)
      if (response.status === 200) {
        this.$message({
          showClose: true,
          message: 'Form Submitted',
          type: 'success'
        })
        if (Object.prototype.hasOwnProperty.call(response.data, 'json_data')) {
          this.json_fields = response.data.json_field
          return response.data.json_data
        } else {
          this.$message({
            showClose: true,
            message: 'No reports found!',
            type: 'error'
          })
        }
      }
      // } else {
      //   this.$message({
      //     showClose: true,
      //     type: 'error',
      //     message: 'Please complete the form.'
      //   })
      // }
    },
    getSection () {
      this.$http
        .get('backend.department.section-list/' + this.reportForm.selectedDept)
        .then(res => {
          this.sections = res.data.body
        })
    },
    getDepartments () {
      this.$http
        .get('backend.department-list-by-company/1')
        .then(res => {
          this.departments = res.data.body
        })
    },
    getCompletedEval () {
      this.$http
        .get('mpp-completed-evaluation-list')
        .then(res => {
          this.completedEvalList = res.data.body
          var uniqueData = [...res.data.body.reduce((map, obj) => map.set(obj.year, obj), new Map()).values()]
          this.yearList = uniqueData
        })
    }
  }
}
</script>
