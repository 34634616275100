<template>
  <div>
    <el-button style="background:#EB2C3E; color:white; float: right;" size="mini" @click="dialogUpload = true">Upload File</el-button>
    <el-dialog title="Upload File" :visible.sync="dialogUpload">
      <el-row type="flex" justify="center">
        <div style="width: 100%; height: 100%;">
          <form>
            <input type="file" @change="uploadFile" style="opacity: 0;" id="file-input" :disabled="File.length > 0" tabindex="-1"/>
            <label for="file-input" style="width: 100%;">
              <div style="border-style:dotted; border-radius:5px; width:100%; height: 150px; cursor: pointer;" @dragover.prevent @drop.prevent :disabled="File.length > 0">
                <div @drop="dragFile" style="padding: 70px 0; text-align: center;">
                  Click here or Drag files here
                </div>
              </div>
            </label>
          </form>
          <div v-if="File.length">
            <ul v-for="(file, index) in File" :key="index">
              <li><span>{{file.name}}</span><span style="float: right;"><el-button size="mini" @click="remove(index)">X</el-button></span></li>
            </ul>
          </div>
        </div>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">Cancel</el-button>
        <el-button type="primary" @click="executeUploadFile" style="background:#EB2C3E; color:white; font-weight: normal !important;">Upload</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      File: [],
      dialogUpload: false
    }
  },
  props: ['endpoint', 'selectedEval'],
  methods: {
    cancel () {
      this.File = []
      this.dialogUpload = false
      document.getElementById('file-input').value = ''
    },
    remove (index) {
      this.File.splice(index, 1)
      document.getElementById('file-input').value = ''
    },
    executeUploadFile () {
      if (this.File.length !== 0) {
        var bodyFormData = new FormData()
        bodyFormData.append('hme_id', this.selectedEval)
        bodyFormData.append('file_upload', this.File[0])
        this.$httpfile
          .post('mpp.evaluation-upload-file', bodyFormData)
          .then(res => {
            if (res.data.StatusCode) {
              this.$message({
                showClose: true,
                message: 'Form Submitted',
                type: 'success'
              })
              this.dialogUpload = false
              this.File = []
              document.getElementById('file-input').value = ''
              this.$emit('success', true)
            } else {
              this.$message({
                showClose: true,
                message: res.data.body,
                type: 'error'
              })
              document.getElementById('file-input').value = ''
            }
          })
      } else {
        this.$message({
          showClose: true,
          message: 'Please attach a file before uploading!',
          type: 'error'
        })
      }
    },
    uploadFile (e) {
      console.log(e)
      this.File = Array.from(e.target.files)
    },
    dragFile (e) {
      var fileArray = Array.from(e.dataTransfer.files)
      if (fileArray.length > 1) {
        this.$message({
          showClose: true,
          message: 'Only one file is allowed!',
          type: 'error'
        })
      } else {
        this.File = fileArray
      }
    }

  }
}
</script>
